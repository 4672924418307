import parse from 'html-react-parser';
import CryptoJS from 'crypto-js';

function fadeOut(el) {
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none';
    } else {
      requestAnimationFrame(fade);
    }
  })();
}
function fadeIn(el, display) {
  el.style.opacity = 0;
  el.style.display = display || 'block';

  (function fade() {
    let val = parseFloat(el.style.opacity);
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

const getURLParam = (URLquery, value) => {
  if (URLquery !== undefined) {
    const arrayQuery = URLquery.split(/&/);
    let objQuery = {};
    for (const element of arrayQuery) {
      const item = element.split(/=/);
      if (item[0] === 'page' || item[0] === 'size') {
        objQuery[item[0]] = parseInt(item[1]);
      } else {
        objQuery[item[0]] = item[1];
      }
    }
    const URLParam = objQuery[value];

    return URLParam;
  } else {
    return null;
  }
};
const getURLFilter = URLquery => {
  const filterNames = ['author', 'category', 'processingResult', 'type', 'sort'];

  if (URLquery !== undefined) {
    const arrayQuery = URLquery.split(/&/);
    let stringQuery = '';
    for (const element of arrayQuery) {
      const item = element.split(/=/);
      if (filterNames.includes(item[0])) {
        stringQuery += '&' + element;
      }
    }
    return stringQuery;
  } else {
    return null;
  }
};
function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
}

function listIncludes(list, target) {
  const isInList = list.some(el => {
    return el.name === target;
  });
  return isInList;
}

function parseText(text) {
  let content = text.replace('<br>', '');
  content = parse(content, {
    replace: domNode => {
      if (domNode.type === 'text' && domNode.data.includes('<iframe')) {
        var iframe = parse(domNode.data);
        return <div className='iframe-container'>{iframe}</div>;
      }
    },
  });
  return content;
}
function tryDecode(data) {
  try {
    return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return data;
  }
}
export { fadeIn, fadeOut, getURLParam, objToQueryString, getURLFilter, listIncludes, parseText, tryDecode };
